<template>
	<div class="detail-card bg-white border">
		<div
			class="card--header d-flex align-items-center pa-2 border-bottom"
			v-on:click="open_detail = !open_detail"
		>
			<div class="font-level-3-bold">Company Details</div>
			<v-spacer></v-spacer>
			<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
		</div>
		<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
			<table class="w-100">
				<tr>
					<td class="fw-500 px-3 py-2" style="min-width: 9.1rem">Company UEN</td>
					<td class="fw-600 px-3 py-1">
						<template v-if="customerDetail && customerDetail.company_uen">
							{{ customerDetail.company_uen }}</template
						>
						<template v-else> <em class="text-muted">no company uen</em></template>
					</td>
				</tr>

				<tr>
					<td class="fw-500 px-3 py-2">Industry</td>
					<td class="fw-600 px-3 py-1">
						<template v-if="customerDetail && customerDetail.industry">
							{{ customerDetail.industry }}</template
						>
						<template v-else> <em class="text-muted">no industry</em></template>
					</td>
				</tr>

				<tr>
					<td class="fw-500 px-3 py-2">Remark</td>
					<td class="fw-600 px-3 py-1 text-justify" style="white-space: pre-line">
						<div style="white-space: pre-wrap" v-if="customerDetail && customerDetail.remark">
							{{ customerDetail.remark }}
						</div>
						<template v-else> <em class="text-muted">no Remark</em></template>
					</td>
				</tr>
			</table>
		</div>
	</div>
</template>
<script>
export default {
	name: "Company-Details",
	props: {
		customerDetail: {
			type: Object,
		},
	},
	data() {
		return {
			open_detail: true,
		};
	},
};
</script>
